import { DateTime } from 'luxon';
import { Card, CardContainer, CardTitle, DatePicker, LoadingSpinner, } from '@mesa-labs/mesa-ui';
import { types as api } from '@mesa-labs/mesa-api';

import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  barChartOptions,
  CurrencyFormatter,
  enumValueToSelection,
  heatMapOptions,
  IdentityFormatter,
  runningStatisticToInterPeriodGrowthXyData,
  toFilterEnum,
  useTimezoneSelection,
} from '../../utils';
import { FilterCell, FilterRow, FilterSection, FilterSpan, FilterTitle, } from '../common/Filters';
import Select from '../common/Select';
import PartnerSelect from '../common/PartnerSelect';
import {
  useGetPartnerIntegrationMetadataQuery,
  useGetPartnerIntraPeriodInvoiceSeriesQuery
} from '../../redux/api/invoices';
import ReactApexChart from 'react-apexcharts';
import { TimeSeriesPoint } from '@mesa-labs/mesa-api/dist/types';

const PartnerIntegrationsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const calendarToHeatMapSeries = (response: api.PartnerPeriodicInvoiceImportSummaryResponse | undefined): ApexAxisChartSeries => {
  const groupedByHour = (response?.dailySummaries?.timeSeries || [])
    .filter((x) => {
      const hourInPst = DateTime.fromISO(x.beginningAt).setZone('America/Los_Angeles').hour;
      return hourInPst >= 1 && hourInPst <=15;
    })
    .reduce((prev, curr) => {
    const value = useTimezoneSelection(DateTime.fromISO(curr.beginningAt)).toLocaleString(DateTime.DATE_SHORT);

    return {
      ...prev,
      [value]: [...(prev[value] || []), curr],
    };
  }, {} as { [index: string]: readonly TimeSeriesPoint[] });

  return Object.entries(groupedByHour).map(([yearMonth, days]) => ({
    name: yearMonth,
    data: days.map((d) => ({
      y: (d as Record<string, any>)['valueDuringPeriod'],
      x: useTimezoneSelection(DateTime.fromISO(d.beginningAt)).toLocaleString(DateTime.TIME_SIMPLE)
    })),
  }));
};

function PartnerIntegrations(): React.ReactElement | null {
  const [beginningAt, setBeginningAt] = useState(useTimezoneSelection(DateTime.now().minus({month: 1}).startOf('day'))
    .toJSDate());
  const [endingAt, setEndingAt] = useState(useTimezoneSelection(DateTime.now().endOf('day'))
    .toJSDate());
  const [partner, setPartner] = useState<api.Partners | undefined>(undefined);
  const [periodization, setPeriodization] = useState(enumValueToSelection(api.TimeSeriesPeriodization.DAY));

  const PeriodizationSelections = toFilterEnum(api.TimeSeriesPeriodization);

  const filters = {
    beginningAt: beginningAt.toISOString(),
    endingAt: endingAt.toISOString(),
    partnerId: partner,
    periodization: periodization.value
  };

  const {
    data: partnerIntegrationMetadata,
    isFetching: isPartnerIntegrationMetadataFetching
  } = useGetPartnerIntegrationMetadataQuery(filters);

  const {
    data: partnerIntraPeriodInvoiceSeries
  } = useGetPartnerIntraPeriodInvoiceSeriesQuery(filters)

  const hourlySeries = useMemo(() => calendarToHeatMapSeries(partnerIntraPeriodInvoiceSeries), [partnerIntraPeriodInvoiceSeries]);

  console.log(JSON.stringify(hourlySeries));

  const importedInvoiceFileCountSeries: ApexAxisChartSeries = useMemo(() => {
    if (!partnerIntegrationMetadata) {
      return [];
    }

    return [
      {
        name: 'Imported Invoice File Count',
        data: runningStatisticToInterPeriodGrowthXyData(partnerIntegrationMetadata.importedInvoiceFileCount),
      }
    ];
  }, [partnerIntegrationMetadata]);

  const importedInvoiceAmountSeries: ApexAxisChartSeries = useMemo(() => {
    if (!partnerIntegrationMetadata) {
      return [];
    }

    return [
      {
        name: 'Imported Invoice Amount Sum',
        data: runningStatisticToInterPeriodGrowthXyData(partnerIntegrationMetadata.importedInvoiceAmount),
      }
    ];
  }, [partnerIntegrationMetadata]);

  const importedRaFileCountSeries: ApexAxisChartSeries = useMemo(() => {
    if (!partnerIntegrationMetadata) {
      return [];
    }

    return [
      {
        name: 'Imported Reconciliation File Count',
        data: runningStatisticToInterPeriodGrowthXyData(partnerIntegrationMetadata.importedRaFileCount),
      }
    ];
  }, [partnerIntegrationMetadata]);

  const importedRaFileAmountSeries: ApexAxisChartSeries = useMemo(() => {
    if (!partnerIntegrationMetadata) {
      return [];
    }

    return [
      {
        name: 'Imported Reconciliation Amount Sum',
        data: runningStatisticToInterPeriodGrowthXyData(partnerIntegrationMetadata.importedRaAmount),
      }
    ];
  }, [partnerIntegrationMetadata]);

  const importedWithholdingFileCountSeries: ApexAxisChartSeries = useMemo(() => {
    if (!partnerIntegrationMetadata) {
      return [];
    }

    return [
      {
        name: 'Imported Withholding File Count',
        data: runningStatisticToInterPeriodGrowthXyData(partnerIntegrationMetadata.importedWithholdingFileCount),
      }
    ];
  }, [partnerIntegrationMetadata]);

  const importedWithholdingFileAmountSeries: ApexAxisChartSeries = useMemo(() => {
    if (!partnerIntegrationMetadata) {
      return [];
    }

    return [
      {
        name: 'Imported Withholding Amount Sum',
        data: runningStatisticToInterPeriodGrowthXyData(partnerIntegrationMetadata.importedWithholdingFileCount),
      }
    ];
  }, [partnerIntegrationMetadata]);

  const importedColors = ['rgba(60, 205, 50, 1)'];
  const raColors = ['rgb(65, 105, 225)'];
  const withholdingColors = ['rgb(139,0,0)'];

  return (
    <PartnerIntegrationsPageContainer>
      <FilterSection
        filterHeader="Partner Integrations"
      >
        <FilterRow>
          <FilterCell>
            <FilterTitle>Partner</FilterTitle>
            <PartnerSelect
              selectedPartner={partner}
              onSelectedPartnerChange={(p) => setPartner(p!)}
              allowNoPartner
            />
          </FilterCell>
          <FilterCell>
            <FilterTitle>Periodization</FilterTitle>
            <Select
              value={periodization}
              onChange={setPeriodization}
              options={PeriodizationSelections}
            />
          </FilterCell>
          <FilterSpan columns={2}>
            <FilterCell>
              <FilterTitle>Start Date</FilterTitle>
              <DatePicker
                onChange={(value) => setBeginningAt(useTimezoneSelection(value.startOf('day')).toJSDate())}
                value={DateTime.fromJSDate(beginningAt)}
              />
            </FilterCell>
            <FilterCell>
              <FilterTitle>End Date</FilterTitle>
              <DatePicker
                onChange={(value) => setEndingAt(useTimezoneSelection(value.endOf('day')).toJSDate())}
                value={DateTime.fromJSDate(endingAt)}
              />
            </FilterCell>
          </FilterSpan>
        </FilterRow>
      </FilterSection>
      <CardContainer>
        <Card minHeight="400px">
          <CardTitle>Imported Invoice Files (Count)</CardTitle>
          {(!isPartnerIntegrationMetadataFetching && (
            <ReactApexChart options={barChartOptions(IdentityFormatter, importedColors)} series={importedInvoiceFileCountSeries} type="bar" height={350} />
          )) || <LoadingSpinner height='365px' />}
        </Card>
        <Card minHeight="400px">
          <CardTitle>Imported Invoice Files (Open Amount)</CardTitle>
          {(!isPartnerIntegrationMetadataFetching && (
            <ReactApexChart options={barChartOptions(CurrencyFormatter, importedColors)} series={importedInvoiceAmountSeries} type="bar" height={350} />
          )) || <LoadingSpinner height='365px' />}
        </Card>
      </CardContainer>
      <CardContainer>
        <Card minHeight="400px">
          <CardTitle>Imported RA Files (Count)</CardTitle>
          {(!isPartnerIntegrationMetadataFetching && (
            <ReactApexChart options={barChartOptions(IdentityFormatter, raColors)} series={importedRaFileCountSeries} type="bar" height={350} />
          )) || <LoadingSpinner height='365px' />}
        </Card>
        <Card minHeight="400px">
          <CardTitle>Imported RA Files (Settlement Amount)</CardTitle>
          {(!isPartnerIntegrationMetadataFetching && (
            <ReactApexChart options={barChartOptions(CurrencyFormatter, raColors)} series={importedRaFileAmountSeries} type="bar" height={350} />
          )) || <LoadingSpinner height='365px' />}
        </Card>
      </CardContainer>
      <CardContainer>
        <Card minHeight="400px">
          <CardTitle>Imported Withholding Files (Count)</CardTitle>
          {(!isPartnerIntegrationMetadataFetching && (
            <ReactApexChart options={barChartOptions(IdentityFormatter, withholdingColors)} series={importedWithholdingFileCountSeries} type="bar" height={350} />
          )) || <LoadingSpinner height='365px' />}
        </Card>
        <Card minHeight="400px">
          <CardTitle>Imported Withholding Files (Open Amount)</CardTitle>
          {(!isPartnerIntegrationMetadataFetching && (
            <ReactApexChart options={barChartOptions(CurrencyFormatter, withholdingColors)} series={importedWithholdingFileAmountSeries} type="bar" height={350} />
          )) || <LoadingSpinner height='365px' />}
        </Card>
      </CardContainer>
      <CardContainer>
        <Card>
          <CardTitle>Hourly Invoice Import Count</CardTitle>
          {(!isPartnerIntegrationMetadataFetching && (
            <ReactApexChart
              options={heatMapOptions(IdentityFormatter)}
              series={hourlySeries}
              type="heatmap"
              height={600}
            />
          )) || <LoadingSpinner height='365px' />}
        </Card>
      </CardContainer>
    </PartnerIntegrationsPageContainer>
  );
}

export default PartnerIntegrations;
